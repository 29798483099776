@import url("https://fontsgoogleapis.com/css?family=Press+Start+2P");
@import url("https://fontsgoogleapis.com/css?family=VT323");

.App {
  text-align: center;
  font-family: "Press Start 2P", "VT323";
  color: black;
}

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.font-face-ror{
  font-family: "ReggaeOneRegular";
}

.font-face-rror{
  font-family: "RocknRollOneRegular";
}